import { useApolloClient } from "@apollo/client"
import { useRef } from "react"
import InView from "react-intersection-observer"
import ReactPlaceholder from "react-placeholder/lib"
import tw from "twin.macro"

import GiftOptionStoreTile from "./GiftOptionStoreTile"
import ProductStoreTile from "./ProductStoreTile"

import { useGlobalState } from "@/common/GlobalState"
import { Loader } from "@/common/UI"
import { fetchGiftOptionDataByParam } from "@/common/utilities"
import { BrandValueKey } from "@/store/components/BrandValueBadge"
import { DelayedComponent } from "@/store/components/DelayedComponent"
import { GalleryPlaceholder } from "@/store/components/GalleryPlaceholder"
import { useBrowseUrls } from "@/store/StoreHelpers"
import {
  CountryCodeEnum,
  ShippingCountry,
  Store_CategoryCommonFragment,
  Store_GiftOptionStoreTileFragment,
  Store_ProductStoreTileFragment,
} from "@/types/graphql-types"

interface StoreTilesListProps {
  brandValues: BrandValueKey[]
  list: (Store_GiftOptionStoreTileFragment | Store_ProductStoreTileFragment)[]
  // selectedSlug: string | null;
  onSelectGiftOption?: (giftOption: Store_GiftOptionStoreTileFragment) => void
  onSelectProduct?: (giftOption: Store_ProductStoreTileFragment) => void
  loading: boolean
  nextPageIsLoading: boolean
  hasStartedLoading: boolean
  decideIfNextPageShouldLoad: (index: number) => void
  minDisplayPrice: number
  selectedCategory: Store_CategoryCommonFragment | null
  pushNewUrl: (path: string, state?: Record<string, string>) => void
  selectedShippingCountry: ShippingCountry
  showProductShippingPrice?: boolean
  showProductBrandName?: boolean
  isGiftCard?: boolean
}

export function StoreTilesList({
  brandValues,
  list,
  onSelectGiftOption,
  onSelectProduct,
  loading,
  nextPageIsLoading,
  hasStartedLoading,
  decideIfNextPageShouldLoad,
  minDisplayPrice,
  selectedCategory,
  selectedShippingCountry,
  pushNewUrl,
  showProductShippingPrice,
  showProductBrandName,
  isGiftCard,
}: StoreTilesListProps) {
  const client = useApolloClient()
  // Track prefetched gift options in a map, mapping id to date prefetched.
  const prefetchedGiftOptions = useRef<{ [id: string]: Date }>({}).current
  const [currentRealm] = useGlobalState("currentRealm")

  const { generateBrowseUrl, generatePdpUrl } = useBrowseUrls()

  async function prefetchGiftOption(slug?: string | null) {
    if (!slug) {
      return
    }

    const lastFetched = prefetchedGiftOptions[slug]

    // Never fetched or fetched > 5 minutes ago
    if (
      !lastFetched ||
      new Date().getTime() - lastFetched.getTime() > 1000 * 60 * 5
    ) {
      if (process.env.NODE_ENV === "development") {
        console.log("Prefetching gift option:", slug)
      }

      await fetchGiftOptionDataByParam({
        client: client,
        param: slug,
        isPrefetch: true,
      })

      prefetchedGiftOptions[slug] = new Date()
    } else {
      if (process.env.NODE_ENV === "development") {
        console.log(
          `Skipping prefetch for ${slug}: last prefetched ${
            (new Date().getTime() - lastFetched.getTime()) / 1000
          }s ago`,
        )
      }
    }
  }

  // When clicking a single product gift option, navigate directly to the PDP.
  function getEffectiveGiftOptionHref(
    giftOption: Store_GiftOptionStoreTileFragment,
  ) {
    if (giftOption.singleProductSlug) {
      return generatePdpUrl({
        productSlug: giftOption.singleProductSlug,
        brandSlug: giftOption.brand.slug,
      })
    } else {
      return generateBrowseUrl({
        giftOptionSlug: giftOption.slug,
        ignoreCategory: true,
      })
    }
  }

  const optionsList = list.map((item, index) => {
    return (
      <InView
        // This visibility sensor fires onChange when the gift option
        // appears scrolled in, even partially. We prefetch the gift
        // option when it does.
        onChange={(isVisible) => {
          // On became visible, start preloading.
          if (isVisible) {
            if (item.__typename === "GiftOption") {
              prefetchGiftOption(item.slug)
            }
            decideIfNextPageShouldLoad(index) // Check useGiftOptions for logic which decides if the next page is loaded
          }
        }}
        key={item.id}
        css={[tw`h-full`]}
      >
        {item.__typename === "GiftOption" ? (
          <GiftOptionStoreTile
            giftOption={item}
            onClick={() => {
              onSelectGiftOption?.(item)

              pushNewUrl(getEffectiveGiftOptionHref(item), {
                name: item.name,
              })
            }}
            href={getEffectiveGiftOptionHref(item)}
            brandValuesFilterValue={brandValues}
            minDisplayPrice={minDisplayPrice}
            selectedShippingCountry={selectedShippingCountry}
          />
        ) : item.__typename === "Product" ? (
          <ProductStoreTile
            product={item}
            onClick={() => {
              onSelectProduct?.(item)

              pushNewUrl(
                generatePdpUrl({
                  productSlug: item.slug,
                  brandSlug: item.brand.slug,
                }),
              )
            }}
            href={generatePdpUrl({
              productSlug: item.slug,
              brandSlug: item.brand.slug,
            })}
            brandValuesFilterValue={brandValues}
            minDisplayPrice={minDisplayPrice}
            showShippingPrice={showProductShippingPrice}
            showBrandName={showProductBrandName}
            isGiftCard={isGiftCard}
            selectedShippingCountry={selectedShippingCountry}
          />
        ) : null}
      </InView>
    )
  })

  const placeholderList = (
    <DelayedComponent waitBeforeShow={100}>
      <ReactPlaceholder
        showLoadingAnimation={true}
        ready={!loading}
        customPlaceholder={<GalleryPlaceholder cols={3} rows={2} />}
      >
        {optionsList}
      </ReactPlaceholder>
    </DelayedComponent>
  )

  const showGiftCardNoResults =
    currentRealm === "plus" &&
    selectedCategory?.name === "Gift Cards" &&
    selectedShippingCountry.code === CountryCodeEnum.GLOBAL

  return (
    <>
      {optionsList.length > 0 ||
      loading ||
      nextPageIsLoading ||
      !hasStartedLoading ? (
        optionsList.length > 0 ? (
          <>
            {optionsList}
            <div tw="grid items-center col-span-full">
              {nextPageIsLoading && (
                <Loader tw="self-center  place-self-center" />
              )}
            </div>
          </>
        ) : (
          placeholderList
        )
      ) : (
        <div
          css={[
            tw`flex flex-col items-center py-16 w-full`,
            { gridColumn: "1 / span 3" },
          ]}
        >
          {showGiftCardNoResults ? (
            <div tw="font-medium text-gray-500">
              Pick a country to view available gift cards{" "}
            </div>
          ) : (
            <>
              <div tw="font-medium text-gray-500">No Results</div>
              <div tw="text-gray-400">Try changing your filters.</div>
            </>
          )}
        </div>
      )}
    </>
  )
}
