import { CountryFlag } from "@/common/CountryFlag"
import FilterButton from "@/store/filters/FilterButton"
import { CountryCodeEnum, ShippingCountry } from "@/types/graphql-types"

interface Props {
  country: ShippingCountry
  open: boolean
  onOpen: () => void
}

const CountryListFilterButton = ({ country, onOpen, open }: Props) => (
  <FilterButton
    onClick={onOpen}
    filterIsActive={country.code !== CountryCodeEnum.US}
    filterDropdownIsOpen={open}
  >
    <div tw="h-6 w-5 flex flex-col justify-center">
      <CountryFlag code={country.code} size="m" />
    </div>
    <span tw="hidden lg:block whitespace-nowrap overflow-ellipsis">
      {country.code === CountryCodeEnum.US ? "USA" : country.name}
    </span>
  </FilterButton>
)

export default CountryListFilterButton
