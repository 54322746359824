import { useState } from "react"
import tw from "twin.macro"

import { CountrySelectorItem } from "./CountrySelectorItem"

import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg"
import DomesticShippingModal from "@/store/components/modals/DomesticShippingModal"
import GlobalShippingCostModal from "@/store/components/modals/GlobalShippingCostModal"
import { CountryCodeEnum, ShippingCountry } from "@/types/graphql-types"

interface Props {
  countries: ShippingCountry[]
  selectedCountry: ShippingCountry
  onSelectCountry: (country: ShippingCountry) => void
  variant: "domestic" | "global"
}

export const CountryGroup = ({
  countries,
  selectedCountry,
  onSelectCountry,
  variant,
}: Props) => {
  const [openModal, setOpenModal] = useState(false)

  const domesticWithOnlyUSCountry =
    variant === "domestic" &&
    countries.every((c) => c.code === CountryCodeEnum.US)

  return (
    <>
      <div tw="bg-white sticky top-0">
        <div
          tw="py-2 px-6 flex items-center justify-between"
          css={[
            variant === "domestic" && tw`bg-[#16A34A1A]`,
            variant === "global" && tw`bg-gray-100`,
          ]}
        >
          <span
            tw="text-base font-medium"
            css={[
              variant === "domestic" && tw`text-[#16A34A]`,
              variant === "global" && tw`text-gray-600`,
            ]}
          >
            {variant === "domestic" && "Domestic "}
            {variant === "global" && "Global "}
            shipping
          </span>
          {!domesticWithOnlyUSCountry && (
            <InfoIcon
              tw="h-5 w-5 cursor-pointer"
              css={[
                variant === "domestic" && tw`text-[#60C083]`,
                variant === "global" && tw`text-gray-400`,
              ]}
              onClick={() => setOpenModal(true)}
            />
          )}
        </div>
      </div>
      <div tw="p-3 flex flex-col gap-1">
        {countries.map((country) => (
          <CountrySelectorItem
            key={country.code}
            country={country}
            selected={selectedCountry.code === country.code}
            onClick={onSelectCountry}
          />
        ))}
      </div>

      {variant === "domestic" && (
        <DomesticShippingModal
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
      {variant === "global" && (
        <GlobalShippingCostModal
          withDomesticSupport
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  )
}
