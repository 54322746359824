import { useRef, useState } from "react"

import { useGlobalState } from "@/common/GlobalState"
import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useShippingCountriesSelector } from "@/store/hooks/useShippingCountriesSelector"
import {
  CountryCodeEnum,
  ShippingCountryGroupFragment,
} from "@/types/graphql-types"

export const useGlobalShippingUpsell = () => {
  const { hasFeature } = useFeatureAccess()
  const { selectedShippingCountry } = useShippingCountriesSelector()
  const [openModal, setOpenModal] = useState(false)
  const [currentRealm] = useGlobalState("currentRealm")
  const isConsumerRealm = currentRealm === "consumer"
  const shipsToUS = useRef(false)

  const closeModal = () => setOpenModal(false)

  const checkForGlobalShipping = (
    shippingCountryGroup: ShippingCountryGroupFragment,
  ) => {
    shipsToUS.current =
      shippingCountryGroup.domestic?.includes(CountryCodeEnum.US) ?? false

    const consumerCheck =
      isConsumerRealm &&
      (selectedShippingCountry.code !== CountryCodeEnum.US ||
        !shipsToUS.current)
    const businessCheck =
      !hasFeature("pro_plan") &&
      shippingCountryGroup.globalRelay?.includes(selectedShippingCountry.code)
    const shouldOpenModal = consumerCheck || businessCheck

    if (shouldOpenModal) {
      setOpenModal(true)
    }

    return shouldOpenModal
  }

  return {
    openGlobalShippingUpsellModal: openModal,
    closeGlobalShippingUpsellModal: closeModal,
    checkForGlobalShipping,
    shipsToUS: shipsToUS.current,
  }
}
